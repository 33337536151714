import React from 'react'

import { Container } from '../../components/Container'
import { Layout } from '../../components/Layout'
import { FirstTeam } from '../../components/FirstTeam'
import { SEO } from '../../components/Seo'
import { Staff } from '../../components/Staff'
import {
  TabContainer,
  Tabs,
  TabTitle,
  TabContent,
  TabPane,
} from '../../components/Tabs'

export default function FirstTeamPage() {
  return (
    <Layout>
      <SEO title={'Prima Squadra'} />

      <Container>
        <TabContainer id={'tab-prima-squadra'} defaultActiveKey={'team'}>
          <Tabs>
            <TabTitle eventKey={'team'}>Prima Squadra</TabTitle>

            <TabTitle eventKey={'staff'}>Staff Tecnico</TabTitle>
          </Tabs>

          <TabContent>
            <TabPane eventKey={'team'}>
              <FirstTeam />
            </TabPane>

            <TabPane eventKey={'staff'}>
              <Staff />
            </TabPane>
          </TabContent>
        </TabContainer>
      </Container>
    </Layout>
  )
}
