import React from 'react'
import { Nav, NavLink, NavLinkProps } from 'react-bootstrap'
import { BsPrefixComponent } from 'react-bootstrap/helpers'
import classNames from 'classnames'

import { Title } from '../Title'

import * as classes from './tabs.module.scss'

export const Tabs: React.FC = ({ children }) => (
  <Nav className={classNames(classes.nav, 'justify-content-center')}>
    {children}
  </Nav>
)

interface TabTitleProps extends NavLinkProps {
  [key: string]: any
}

export const TabTitle: React.FC<TabTitleProps> = ({ children, ...props }) => (
  <Nav.Item>
    <Nav.Link className={classes.navLink} {...props}>
      <Title>{children}</Title>
    </Nav.Link>
  </Nav.Item>
)

export { TabContainer, Tab, TabPane, TabContent } from 'react-bootstrap'
